/*
  Given an email input field, an associated password field from the same form will
  be looked up and the time of the last input of both fields will be tracked.
  If both fields were updated in quick succession then isFilledWithPasswordManager
  will return true.
*/
const THRESHOLD_MS = 100;

export default class PasswordManagerDetection {
  private emailInput: HTMLInputElement;
  private passwordInput: HTMLInputElement | null | undefined;
  private emailLastUpdated?: number;
  private passwordLastUpdated?: number;

  // Pass a reference to the email input field and an associated password field of the same form will be looked up.
  constructor(emailInput: HTMLInputElement) {
    this.emailInput = emailInput;
    this.passwordInput = this.findPasswordInput();
  }

  // Add event listeners
  start() {
    this.emailInput.addEventListener('input', this.trackEmailChange.bind(this));
    this.passwordInput?.addEventListener(
      'input',
      this.trackPasswordChange.bind(this),
    );
  }

  // Remove event listeners
  stop() {
    this.emailInput.removeEventListener('input', this.trackEmailChange);
    this.passwordInput?.removeEventListener('input', this.trackPasswordChange);
  }

  // Check if both email and password were updated in too quick succession to be manual human input
  isFilledWithPasswordManager() {
    if (
      this.emailLastUpdated !== undefined &&
      this.passwordLastUpdated !== undefined
    ) {
      const result =
        Math.abs(this.emailLastUpdated - this.passwordLastUpdated) <
        THRESHOLD_MS;
      return result;
    }
  }

  private trackEmailChange(event: Event) {
    this.emailLastUpdated = event.timeStamp;
  }

  private trackPasswordChange(event: Event) {
    this.passwordLastUpdated = event.timeStamp;
  }

  private findPasswordInput() {
    return this.emailInput.form?.querySelector(
      'input[type="password"]',
    ) as HTMLInputElement;
  }
}
